import React from 'react';
import { bool, arrayOf, object } from 'prop-types';

import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './assets/styles.css';

import { photos } from './api/photo-data';

/**
 * function to render Photo Slider
 * This component using `react-image-gallery`
 * @returns {JSX.Element}
 */
function Gallery({ data }) {
  return (
    <ImageGallery
      autoPlay
      infinite
      lazyLoad
      items={data}
      showBullets={false}
      showFullscreenButton={false}
      showThumbnails={false}
    />
  );
}

Gallery.propTypes = {
  data: arrayOf(object),
  withTitle: bool,
};

Gallery.defaultProps = {
  data: photos,
  withTitle: false,
};

export default React.memo(Gallery);
